<template v-show="showModal">
<div class="fixed-top bg-secondary bg-opacity-50" style="height: 100%;" v-show="showModal">
  <div class="modal fade show" style="display: block;" >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button class="btn" type="button" @click='$emit("closeModal",false)'><i class="bi bi-x-lg"></i></button>
        </div>
        <div class="modal-body">
            <slot name="body">{{ body }}</slot>
        </div>
        <div class="modal-footer">
            <slot name="footer">
                {{ footer }}<br>
                {{ footer2 }}
                <button type="button" class="btn btn-secondary" @click='$emit("closeModal",false)'>Close</button>
            </slot>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name:"modal",
  props:{
    showModal:{
        type: Boolean,
        default:true,
    },
    title:{
      type: String,
      default:'Submit Form'
    }
  },
  setup(){
    const body = '<template #body> example code of modal body </template>'
    const footer = '<template #footer> example code of modal footer </template>'
    const footer2 = '<button type="button" class="btn btn-secondary">Close</button>'

    return{
        body,footer,footer2
    }
  }
}
</script>