<template>
  <div class="col rounded-3 bg-white min-vh-100 p-3 ">
    <form>
      <!-- provenance ID -->
      <div class="d-flex flex-wrap mb-3">
        <div class="d-sm-inline-flex me-2 fw-bold" >
          Provenance Keyphrase:
        </div>
        <div class="flex-fill w-75">
          <DropdownInput :options = "keyphraseArr" :value = "this.data.keyphrase" class="form-control py-0 ps-0" :class="{'is-invalid':v$.keyphrase.$error}" @on_change_input_value = "onTextChange"></DropdownInput>
          <div v-if="this.v$.keyphrase.$error" class="px-2 text-danger" style="font-size: 90%"> 
            {{v$.keyphrase.$errors[0].$message}}
          </div>
        </div>
      </div>
      <!-- Metadata Container -->
      <div id="MetadataContainer">
        <div class="pb-2" :class="{'d-flex': Object.keys(data.metadata).length !== 0}">
          <div id="title" class="col fw-bold ">
            Metadata
          </div>
          <div id="Notice" v-if="Object.keys(data.metadata).length === 0" class="fst-italic lh-sm" style="font-size: 0.65rem">
            You may create a provenance keyphrase key-value pair, you need the key-phrase for adding a new block into same provenance in the future. 
            Example:  {"keyphrase":"xxx"} or 
            <span @click="autoKeyphrase()" role="button">  click here. </span>
          </div>
          <div id="Edit/DoneBtns" class="col-auto" v-if="Object.keys(data.metadata).length !== 0">
            <btn v-if="edit" @click="editDone" :name="'Done'" class="btn-sm p-1"></btn>
            <btn v-else @click="editMetadata" :name="'Edit'" class="btn-sm"></btn>
          </div>
        </div>

        <div id="metadataJson" class="pb-2" v-if="Object.keys(data.metadata).length !== 0">
          <div v-if="edit" >
            <textarea v-model="metadataString" class="form-control min-vh-100" :class="{'is-invalid':metadataStringError}"></textarea>
            <span v-if="metadataStringError" class="invalid-feedback" style="">{{metadataStringErrorMessage}}</span>
          </div>
          <div v-else>
            <ul class="p-0" >
              <li v-for="(value,key) in data.metadata" :key="key" class="text-truncate">
                <span>{{key}}:&emsp;</span>
                <span class="text-muted" >{{value}}</span>
              </li>
            </ul>
          </div>
        </div>
        
        <div :class="{'d-none' :edit}">
          <div id="FileUploader" class="text-end">
            <input type="file" @change="getFiles($event)" ref='file' class="d-none" accept="image/png, image/jpeg, application/pdf">
            <span class="border-0" role="button" @click="UploadFile" aria-label="Upload a file" data-bs-toggle="tooltip" title="Can only upload png,jpeg or pdf file only.">
              <i class="bi bi-paperclip"></i>Upload a file
            </span>
          </div>
          <div class="d-sm-flex">
            <div id="KEY" class="col-sm-4 col-12 mb-2 me-2">
              <textarea type="text" v-model="state.key" placeholder="Title" class="form-control" :class="{'is-invalid':b$.key.$error}"></textarea>
              <div class="invalid-feedback" v-if="b$.key.$error">
                {{b$.key.$errors[0].$message}}
              </div>
            </div>
            <div id="VALUE" class="col-sm-8 col-12">
              <textarea type="text" v-model="state.value" placeholder="Details" class="form-control" :class="{'is-invalid':b$.value.$error}"></textarea>
              <div class="invalid-feedback" v-if="b$.value.$error">
                {{b$.value.$errors[0].$message}}
              </div>
            </div>
          </div>
          <Btn @click="add" :name="'Add'" class="btn-sm"></Btn>
          <!-- Create Block Buttons group  -->
          <div class="text-end" role="group" aria-label="Button group" >
            <Btn @click="createBlock" :name="'Create Block'" :color="'green'" class="me-2"></btn>
            <Btn @click="reset" :name="'Reset'" :color="'grey'"></btn>
          </div>
        </div>
      </div>
    </form>
    <!-- Modal from -->
    <modal-from :title="modalTitle" :showModal="showModal" @closeModal="closeModal">
      <template #body >
        <span class="fw-bold">Provenance Keyphrases : </span>
        <span class="text-break lh-sm">{{this.data.keyphrase}}</span>
        <br/><br/>
        <span class="fw-bold">Metadata: </span>
        <ul class="text-break m-0 p-0" style="list-style-type: none;">
          <li v-for="(value,key) in data.metadata" :key="key" class="text-truncate">
            {{key}}: &emsp; 
            <span class="text-muted"> {{value}} </span>
          </li>
        </ul>
      </template>
      <template #footer>
          <button type="button" class="btn btn-warning" @click="confirm"> Confirm </button>
          <button type="button" class="btn btn-secondary" @click="closeModal"> Edit </button>
      </template>
    </modal-from>
 </div>
</template>

<script>
import { reactive } from '@vue/reactivity';
import DropdownInput from '@/components/DropdownInput.vue';
import Btn from '@/components/Btn.vue';
import DataServices from '../../services/data-service';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ModalFrom from '../../components/Modal From.vue';

export default {
  name:"CreateBlock",
  components: { ModalFrom, Btn, DropdownInput },
  data(){
    return{
      keyphraseArr:[],
      edit:false,
      metadataStringError:false,
      metadataStringErrorMessage:'',
      showModal: false,
      modalTitle:'New block details',
      test:''
    }
  },
  setup(){
    const data = reactive({
      keyphrase: '',
      metadata: {},
    });

    const dataRules = {
      keyphrase: { required },
      metadata:{ required },
    };

    const state = reactive({
      key:'',
      value:''
    })
    const stateRule = {
      key: { required },
      value: { required },
    }

    const v$ = useVuelidate(dataRules, data);
    const b$ = useVuelidate(stateRule, state);

    return{
      data, v$, state, b$
    }
  },
  methods:{
    onTextChange (x) {
      this.data.keyphrase = x
    },
    editMetadata(){
      this.edit = true;
      this.metadataStringError = false;
      this.metadataString = JSON.stringify(this.data.metadata)
      this.data.metadata = JSON.parse(this.metadataString)
    },
    editDone(){
      try {
        this.data.metadata = JSON.parse(this.metadataString)
        this.metadataStringError = false;
        this.edit = false;
      } catch(e) {
        this.metadataStringError = true;
        this.metadataStringErrorMessage = e.toString()
      }
    },
    UploadFile(){
      this.$refs.file.click();
    },
    getFiles(files){
      let fileType = files.target.files[0].type
      let fileSize = files.target.files[0].size
      if ((fileType === 'application/pdf' || fileType === 'image/jpeg' || fileType === 'image/png') && fileSize <= 1000000){ //68000=60kb
        const reader = new FileReader()
        reader.onload = (e) => {
          this.state.value = e.target.result
          this.state.key = files.target.files[0].name
        }
        reader.onerror = (e) =>{
          alert(e);
        }
        // reader.readAsBinaryString(files.target.files[0])  
        reader.readAsDataURL(files.target.files[0])  
      } else {
       alert('accept png, jpg, and pdf files and total file size less than 68kB.')
      }
    },
    autoKeyphrase(){
      this.state.key = 'keyphrase'
      if(this.data.keyphrase === ''){
        this.b$.$touch()
      } else {
        this.state.value = this.data.keyphrase
      }
    },
    add(){
      this.b$.$touch()
      if ( this.b$.$error === false ) {
        this.data.metadata[this.state.key.trim()] = this.state.value
        this.state.key = ''
        this.state.value =''
        this.b$.$reset()
      }
    },
    createBlock(){
      console.trace()
      this.v$.$touch()
      if(this.v$.metadata.$error === true){
        this.add()
      }

      if(this.v$.$error === false ){
        this.showModal = true
      }
    },
    async confirm(){
      const x = {
        'provenanceId': this.data.keyphrase,
        "metadata": this.data.metadata
      }
      const entryHash = await DataServices.CreateBlock(x)
      console.log("entryHash",entryHash)
      this.reset()
      this.closeModal()
    },
    reset(){
      this.data.keyphrase = ''
      this.data.metadata = {}
      this.v$.$reset()
      this.state.key = ''
      this.state.value =''
      this.b$.$reset()
      this.edit = false
      this.metadataString = ''
      this.metadataStringError = false
    },
    closeModal(){
      this.showModal = false
    },
  },
  created(){
    let received  = this.$route.query.keyphraseArr
    console.log("received",received)
    if (typeof received === 'string'){
      this.data.keyphrase  = received.split(",")
    } else if (typeof received === 'object') {
      this.data.keyphrase = received[0]
      this.keyphraseArr = received
    }
  },
}
</script>
