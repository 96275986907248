<template>
    <div class="dropdown" >
        <!-- Dropdown Input -->
        <textarea type="text" 
        :placeholder="placeholder"
        v-model="input_value" 
        @click="showOptions()" 
        data-bs-toggle="dropdown" 
        aria-expanded="false"
        class="border-0 w-100"
        @input="input_value = input()"></textarea>
        <!-- Dropdown Menu -->
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu" v-if="options.length !== 0">
            <li class="dropdown-item" 
            @mousedown="selectOption(option)"
            v-for="(option, index) in options"
            :key="index">
             {{ option }}
            </li>
        </ul>
    </div>
    <!-- Dropdown Menu -->
    
</template>

<script>
export default {
    name:'DropdownInput',
    props: {
        options: Array,
        value: String,
        placeholder: String,
    },
    data() {
        return {
            optionsShown: false,
            input_value: 'test',
        }
    },
    methods: {
        input(){
            var value;
            value = this.input_value
            return value
        },
        selectOption(option) {
                this.input_value = option;
                this.optionsShown = false;
        },
        showOptions() {
            this.optionsShown = !this.optionsShown;
        },
    },
    watch: {
        value(){
            this.input_value = this.value
        },
        input_value() {
            this.$emit('on_change_input_value', this.input_value);
        }
    },
    created () {
        this.input_value = this.value;
    },
}
</script>