<template>
<button type="button" class="btn"  
:color="color" :style='style' 
:LoadingStatus='LoadingStatus' 
:class="{'spinner-border':LoadingStatus}">
    <span :class="{'visually-hidden':LoadingStatus}">{{name}}</span>
</button>
</template>

<script>
export default {
    name:"Btn",
    props:{
        color:String,
        name:String,
        LoadingStatus: Boolean,
    },
    setup(props){
        let color = ''
        if (props.color){
            color = props.color.toLowerCase()
        }

        let style=''
        switch(color){
            case 'light-green':
                style = 'color:rgb(109,210,48); background-color:rgb(232, 242, 225)';
                break;
            case 'green':
                style = 'color:rgb(232, 242, 225); background-color:rgb(0,123,131)';
                break;
            case 'grey':
                style = 'color:rgb(241, 243, 245); background-color:rgb(91,115,140)';
                break;
            case 'light-grey':
                style = 'color:rgb(152,169,188); background-color:rgb(236,239,242)';
                break;
            case 'light-yellow':
                style = 'color:rgb(255,171,43); background-color:rgb(245, 236, 223)';
                break;
            case 'light-red':
                style = 'color:rgb(207,23,59); background-color:rgb(250,231,235)';
                break;
            case 'light-blue':
                style = 'color:rgb(63,81,181); background-color:rgb(235,237,247)';
                break;
            default:
                style = 'color:rgb(255,255,255); background-color:rgb(77,124,254)';
                break;
        }


        return{
            style
        }
    },
    
}
</script>
